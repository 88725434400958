body {
  font-size: 100%;
  font-family: $main-font;
}

.app {
  display: flex;
  height: 100vh;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  .left-col {
    width: 100%;
  }
  .right-col {
    border-left: 1px solid black;
    flex: 0 0 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    @media (max-width: 1365px) {
      flex: 0 0 400px;
    }

    @media (max-width: 1023px) {
      border-left: none;
      border-top: 1px solid black;
    }
  }
}

.intro {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  box-sizing: border-box;
  color: black;

  @media (max-width: 767px) {
    padding: 1rem;
  }

  .logo {
    width: 300px;

    img {
      width: 100%;
    }
  }

  .intro-description {
    margin: auto;
    width: 100%;
    max-width: 768px;

    @media (max-width: 1023px) {
      margin: 2rem 0 1rem;
      max-width: 100%;
    }

    h1 {
      margin: 0;
      font-size: 4rem;
      font-weight: 700;

      @media (max-width: 767px) {
        font-size: 3rem;
      }
    }

    p {
      margin: 0;
    }

    .description {
      font-size: 1.2rem;
      line-height: 1.3;
      margin: 1rem 0;
      color: #333;

      &.collapsed {
        max-height: 120px;
        overflow: hidden;

        .inner {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5;
        }
      }
    }
    .collapser {
      font-size: 1rem;
      color: #000;
      font-weight: 700;
      display: flex;
      padding: 0;
      margin-top: 0.5rem;
      height: 20px;
    }
  }

  .copy {
    width: 100%;
    max-width: 768px;
    margin: 1rem auto 0 auto;

    @media (max-width: 1023px) {
      margin: 1rem 0 0 0;
    }

    a {
      color: #000;
    }
  }
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.25s linear;
  width: 100%;
  height: 100%;
}

.button {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
  transition: all 0.25s linear;

  button {
    color: black;
  }

  &:hover {
    background-color: #f1f1f1;
  }

  &.active {
    background-color: black;
    font-weight: 700;
    color: white;

    button {
      color: white;
      font-weight: 700;
    }
  }
}

.input-container {
  input {
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    padding: 0;
    font-size: 1.1rem;
    width: 130px;
  }
}

.submit-button {
  height: 70px;
  color: white;
  background-color: black;
  width: 100%;
  font-size: 1.2rem;
  cursor: pointer;
  border: 0;
  height: calc(100vh / 5);
  font-weight: 700;
}

.donation-form {
  width: 100%;
  height: 100%;
  min-height: 50vh;

  @media (max-width: 1023px) {
    min-height: 60vh;
  }

  .mode-container {
    display: flex;
    height: 20%;

    .button {
      width: 100%;
      height: 100%;
    }
  }

  .prices-container {
    display: flex;
    flex-wrap: wrap;
    height: 60%;

    .button {
      flex: 0 0 50%;
      box-sizing: border-box;
      border-top: 1px solid #000;
      height: calc(100% / 3);

      &:nth-child(odd) {
        border-right: 1px solid #000;
      }
    }
  }

  .submit-container {
    height: 20%;

    .submit {
      background-color: #000;
      color: white;
      font-weight: 700;
      font-size: 1.5rem;

      &[disabled] {
        opacity: 0.75;
      }

      .submit-content {
        display: flex;
        flex-direction: column;

        .stripe {
          margin-top: 0.5rem;
          font-size: 1rem;
          font-weight: 400;
          color: #666;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 499px) {
            font-size: 0.9rem;
          }

          img {
            margin-left: 0.5rem;
            opacity: 0.5;
            @media (max-width: 499px) {
              margin-left: 0.25rem;
              width: 40px;
            }
          }
        }
      }
    }
  }
}

.stripe-error {
  position: fixed;
  border: 0;
  z-index: 1;
  width: 100%;
  max-width: 400px;
  background: white;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 1rem;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0px 6px 21px 5px rgb(0 0 0 / 25%);
  box-shadow: 0px 6px 21px 5px rgb(0 0 0 / 25%);
  display: flex;

  button {
    width: auto;
    margin-left: auto;
  }
}

.donation-response {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0;
      font-size: 4rem;
      font-weight: 700;

      @media (max-width: 768px) {
        font-size: 3rem;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.3;
      margin: 1rem 0;
      color: #333;
    }

    .button {
      background-color: #000;
      color: #fff;
      padding: 0.5rem 2rem;
      font-size: 1.2rem;
      text-decoration: none;
      margin-right: auto;
    }

    svg {
      width: 30px;
      height: 20px;
      fill: #fff;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
